import { Controller } from "@hotwired/stimulus";
import { updateUrlParams } from "../../helpers/url";

// Connects to data-controller="inventories"
export default class extends Controller {
  static targets = [
    "confirmSection",
    "resetButton",
    "actionButton",
    "openAllButton",
    "closeAllButton",
  ];

  resetPrices() {
    this.resetButtonTargets.forEach((btn) => {
      btn.dispatchEvent(new Event("click"));
    });

    this.confirmSectionTarget.classList.add("hidden");
  }

  closeAll(e) {
    e.target.classList.add("hidden");
    this.openAllButtonTarget.classList.remove("hidden");

    this.element
      .querySelectorAll(".util-accordion-toggle .close-accordion-btn")
      .forEach((btn) => {
        btn.click();
      });
  }

  openAll(e) {
    e.target.classList.add("hidden");
    this.closeAllButtonTarget.classList.remove("hidden");

    this.element
      .querySelectorAll(".inventories-size-container")
      .forEach((el) => {
        if (!el.getAttribute("src").includes("open")) {
          updateUrlParams({ target: el, key: "open", value: true });
        }
      });

    setTimeout(() => {
      this.element
        .querySelectorAll(".util-accordion-toggle .open-accordion-btn")
        .forEach((btn) => {
          btn.click();
        });
    }, 0);
  }

  bulkSelected() {
    this.actionButtonTargets.forEach(
      (element) => (element.disabled = !this.anyBulkChecked)
    );
  }

  priceChange() {
    this.confirmSectionTarget.classList.toggle("hidden", !this.anyPriceChecked);
  }

  get bulkCheckboxes() {
    return [...this.element.querySelectorAll(".bulk-checkbox")];
  }

  get anyBulkChecked() {
    return this.bulkCheckboxes.some((box) => box.checked);
  }

  get priceCheckboxes() {
    return [...this.element.querySelectorAll("input[type=checkbox].hidden")];
  }

  get anyPriceChecked() {
    return (
      this.priceCheckboxes.length &&
      this.priceCheckboxes.some((box) => box.checked)
    );
  }
}
