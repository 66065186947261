import { Controller } from "@hotwired/stimulus";
import { updateUrlParams } from "../../helpers/url";

// Connects to data-controller="inventories-checkboxes"
export default class extends Controller {
  selectAll(e) {
    this.checkboxes.forEach((checkbox) => {
      if (!checkbox.disabled) checkbox.checked = e.target.checked;
      if (!e.target.checked) checkbox.indeterminate = false;
    });
  }

  productChecked(e) {
    const targetId = e.target.id;
    const element = e.target;

    this.getCheckboxesChildren(element, ".inventories-product-row").forEach(
      (checkbox) => {
        if (!checkbox.disabled) checkbox.checked = e.target.checked;
        if (!e.target.checked) checkbox.indeterminate = false;
      }
    );

    this.handleSelectAllCheckbox();
    this.updateUrls(e.target.checked, element);
  }

  sizeChecked(e) {
    const element = e.target;

    const productCheckbox = this.getProductParentCheckbox(element);
    productCheckbox.checked = this.isAnySizeChecked(element);

    this.getCheckboxesChildren(element, ".inventories-size-row").forEach(
      (checkbox) => {
        if (!checkbox.disabled) {
          checkbox.checked = e.target.checked;
        }
      }
    );

    this.updateIndeterminateState(
      productCheckbox,
      this.isAllSizesChecked(element),
      this.isAnySizeChecked(element)
    );

    this.handleSelectAllCheckbox();
    this.updateUrls(e.target.checked, element);
  }

  serialChecked(e) {
    const element = e.target;

    const isAnyChecked = this.isAnySerialChecked(element);
    const sizeCheckbox = this.getSizeParentCheckbox(element);
    sizeCheckbox.checked = isAnyChecked;

    const productCheckbox = this.getProductParentCheckbox(element);
    productCheckbox.checked = this.isAnySizeChecked(element);

    this.updateIndeterminateState(
      sizeCheckbox,
      this.isAllSerialChecked(element),
      isAnyChecked
    );

    this.updateIndeterminateState(
      productCheckbox,
      this.isAllSizesChecked(element),
      this.isAnySizeChecked(element)
    );

    this.handleSelectAllCheckbox();
  }

  getCheckboxesChildren(element, parentClass) {
    return element.closest(parentClass).querySelectorAll(".bulk-checkbox");
  }

  handleSelectAllCheckbox() {
    this.selectAllCheckbox.checked = this.isAnyProductChecked();

    this.updateIndeterminateState(
      this.selectAllCheckbox,
      this.isAllProductsChecked(),
      this.isAnyProductChecked()
    );
  }

  isAllProductsChecked() {
    return Array.from(this.productCheckboxes).every(
      (checkbox) => checkbox.checked
    );
  }

  isAnyProductChecked() {
    return Array.from(this.productCheckboxes).some(
      (checkbox) => checkbox.checked
    );
  }

  isAllSerialChecked(element) {
    return Array.from(this.getCheckboxesSiblings(element)).every(
      (checkbox) => checkbox.checked
    );
  }

  isAnySerialChecked(element) {
    return Array.from(this.getCheckboxesSiblings(element)).some(
      (checkbox) => checkbox.checked
    );
  }

  isAllSizesChecked(element) {
    return Array.from(this.getCheckboxSizesSiblings(element)).every(
      (checkbox) => checkbox.checked
    );
  }

  isAnySizeChecked(element) {
    return Array.from(this.getCheckboxSizesSiblings(element)).some(
      (checkbox) => checkbox.checked
    );
  }

  getProductParentCheckbox(element) {
    return element
      .closest(".inventories-product-row")
      .querySelector(".bulk-checkbox-product");
  }

  getSizeParentCheckbox(element) {
    return element
      .closest(".inventories-size-row")
      .querySelector(".bulk-checkbox-size");
  }

  get checkboxes() {
    return this.element.querySelectorAll(".bulk-checkbox");
  }

  get selectAllCheckbox() {
    return this.element.querySelector(".bulk-checkbox-select-all");
  }

  updateIndeterminateState(element, isAllChecked, isAnyChecked) {
    element.indeterminate = !isAllChecked && isAnyChecked;
  }

  get productCheckboxes() {
    return this.element.querySelectorAll(".bulk-checkbox-product");
  }

  getCheckboxesSiblings(element) {
    return element
      .closest(".inventories-serial-container")
      .querySelectorAll(".bulk-checkbox-serial");
  }

  getCheckboxSizesSiblings(element) {
    return element
      .closest(".inventories-size-container")
      .querySelectorAll(".bulk-checkbox-size");
  }

  updateUrls(value, element) {
    const sizeContent = element
      .closest(".inventories-product-row")
      .querySelector(".inventories-size-container");

    if (sizeContent && !sizeContent.querySelector(".util-size-row")) {
      updateUrlParams({
        target: sizeContent,
        key: "bulk_checked",
        value: value,
      });
    }

    const serialContent = element
      ?.closest(".inventories-size-row")
      ?.querySelector(".inventories-serial-container");
    if (serialContent && !serialContent.querySelector(".serial-row")) {
      updateUrlParams({
        target: serialContent,
        key: "bulk_checked",
        value: value,
      });
    }
  }
}
