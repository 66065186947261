export const updateUrlParams = ({
  target,
  key,
  value,
  deleteParam = false,
  urlAttribute = "src",
}) => {
  const url = new URL(target[urlAttribute], window.location.href);

  if (deleteParam) {
    url.searchParams.delete(key);
  } else {
    url.searchParams.set(key, value);
  }

  target[urlAttribute] = url.pathname + url.search;
};
