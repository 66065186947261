import { Controller } from "@hotwired/stimulus";

import GtmController from "./gtm_controller";

// Connects to data-controller="accordion"
export default class extends Controller {
  static values = {
    openText: String,
    closeText: String,
    gtmEvents: { type: Object, default: null },
  };

  connect() {
    this.gtm = new GtmController(this);
  }

  toggle(e) {
    if (this.isOpen) {
      this.closeAccordion(e);
    } else {
      this.openAccordion(e);
    }
  }

  openAccordion(e) {
    e.stopPropagation();
    this.trackEvent();

    this.element.setAttribute("aria-expanded", true);
    this.closeButton.classList.remove("hidden");
    this.openButton.classList.add("hidden");

    this.body.classList.remove("overflow-hidden");
    this.body.classList.add("visible");
    this.body.classList.remove("invisible");

    this.body.style.height = `${this.body.scrollHeight}px`;
    this.screenReaderText.innerHTML = this.closeTextValue;
    // In order to make nested accordions work, we need to reset the height
    const animationDuration = 200;
    setTimeout(() => {
      this.body.style.height = "";
    }, animationDuration);
  }

  closeAccordion(e) {
    e.stopPropagation();
    this.trackEvent();

    this.element.setAttribute("aria-expanded", false);
    this.closeButton.classList.add("hidden");
    this.openButton.classList.remove("hidden");

    this.body.classList.add("overflow-hidden");
    this.body.classList.remove("visible");
    this.body.classList.add("invisible");

    this.screenReaderText.innerHTML = this.openTextValue;
    this.body.style.height = this.body.scrollHeight + "px";

    setTimeout(() => {
      this.body.style.height = "0px";
    });
  }

  trackEvent() {
    if (
      !this.gtmEventsValue &&
      !this.gtmEventsValue.category &&
      !this.gtmEventsValue.action
    )
      return;

    const event = this.gtmEventsValue;
    const gtmAction = this.isOpen ? "_collapse" : "_expand";
    const gtmParams = {
      gtmCategory: event.category,
      gtmLabel: event.label,
      gtmAction: event.action + gtmAction,
    };

    this.gtm.track({ ...gtmParams });
  }

  get id() {
    return this.element.getAttribute("data-accordion-id");
  }

  get isOpen() {
    return this.element.getAttribute("aria-expanded") === "true";
  }

  get openButton() {
    return this.findScopedElementById("open");
  }

  get closeButton() {
    return this.findScopedElementById("close");
  }

  get screenReaderText() {
    return this.findScopedElementById("sr");
  }

  get body() {
    return this.findScopedElementById("body");
  }

  findScopedElementById(scopeId) {
    return this.scope.findElement(
      `[data-accordion-${scopeId}-id="${this.id}"]`
    );
  }
}
