import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="inventories-import-csv"
export default class extends Controller {
  static targets = ["fileInput", "button"];

  import(e) {
    this.fileInputTarget.click();
  }

  submit() {
    this.buttonTarget.setAttribute("disabled", true);
    this.element.querySelector("form").submit();
  }
}
